import React from 'react';
import Content from './Content';
import Meta from './Meta';
import {
  post as postClassName,
} from './Post.module.scss';

const Post = ({ post }) => {
  const {
    created_at,
    modified_at
  } = post.frontmatter;

  return (
    <div className={postClassName}>
      <article>
        <Content post={post}/>
      </article>
      <Meta created_at={created_at} modified_at={modified_at} />
    </div>
  );
};

export default Post;
