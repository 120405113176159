import React from 'react';
import moment from 'moment';
import 'moment/locale/ja';
import { meta__date } from './Meta.module.scss';

const Meta = ({ created_at, modified_at }) => (
  <div>
    <p className={meta__date}>公開 {moment(created_at).format('L')} - 最終更新 {moment(modified_at).format('L')}</p>
  </div>
);

export default Meta;
