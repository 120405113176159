import React from 'react';
import { Link } from 'gatsby';
import {
  content,
  content__body,
  content__category__link,
  content__title,
} from './Content.module.scss';
import Tags from '../Tags';

const Content = ({ post }) => {
  const {
    category,
    tags,
    title,
    description
  } = post.frontmatter;

  const { html } = post;
  const { tagSlugs, categorySlug } = post.fields;

  return (
  <div className={content}>
    <Link to={categorySlug} className={content__category__link}>{category}</Link>
    <h1 className={content__title}>{title}</h1>
    <p>{description}</p>
    <Tags tags={tags} tagSlugs={tagSlugs} />
    <div className={content__body} dangerouslySetInnerHTML={{ __html: html }} />
    <Tags tags={tags} tagSlugs={tagSlugs} />
  </div>
  );
};

export default Content;
