import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Page from '../components/Page';
import Post from '../components/Post';
import RightSidebar from '../components/RightSidebar';
import Sidebar from '../components/Sidebar';

const PostTemplate = ({ data }) => {
  const {
    title: siteTitle,
    subtitle: siteSubtitle,
    logo: logo,
  } = data.site.siteMetadata;

  const { title: postTitle, description: postDescription } =
    data.markdownRemark.frontmatter;

  const metaDescription =
    postDescription !== null ? postDescription : siteSubtitle;

  return (
    <Layout title={`${postTitle} - ${siteTitle}`} description={metaDescription}>
      <Sidebar />
      <Page logo={logo}>
        <Post post={data.markdownRemark} />
      </Page>
      <RightSidebar />
    </Layout>
  );
};

export const query = graphql`
  query PostBySlug($slug: String!) {
    site {
      siteMetadata {
        logo {
          title
          subtitle
          path
          width
          height
        }
        subtitle
        title
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        tagSlugs
        categorySlug
      }
      frontmatter {
        created_at
        modified_at
        description
        tags
        title
        category
      }
    }
  }
`;

export default PostTemplate;
