import React from 'react';
import { Link } from 'gatsby';
import { tags__link } from './Tags.module.scss';

const Tags = ({ tags, tagSlugs }) => (
  <div>
    <p>
      {tagSlugs.map((slug, i) => (
        <Link key={tags[i]} to={slug} className={tags__link}>
          #&nbsp;{tags[i]}
        </Link>
      ))}
    </p>
  </div>
);

export default Tags;
